
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import CommonDate from "@/components/common/Date.vue";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonText from "@/components/common/Text.vue";

  @Component({
    components: {
      CommonDate,
      CommonInfobutton,
      CommonSelect,
      CommonText,
    },
  })
  export default class Main extends Mixins(mixinNencho) {
    get tmp_new_loan() {
      return this.m.new_loans[0];
    }
    set tmp_new_loan(val) {
      this.m.setNewLoan({ index: 0, new_loan: val });
    }

    get is_new_type() {
      return (
        this.tmp_new_loan.lived_on &&
        util.date2unix(this.tmp_new_loan.lived_on) >= util.date2unix("2014-04-01")
      );
    }
    get is_new2_type() {
      return (
        this.tmp_new_loan.lived_on &&
        util.date2unix(this.tmp_new_loan.lived_on) >= util.date2unix("2019-01-01")
      );
    }
    get is_new3_type() {
      return (
        this.tmp_new_loan.lived_on &&
        util.date2unix(this.tmp_new_loan.lived_on) >= util.date2unix("2019-10-01")
      );
    }
    get is_new4_type() {
      return (
        this.tmp_new_loan.lived_on &&
        util.date2unix(this.tmp_new_loan.lived_on) >= util.date2unix("2021-01-01")
      );
    }
    get is_new5_type() {
      return (
        this.tmp_new_loan.lived_on &&
        util.date2unix(this.tmp_new_loan.lived_on) >= util.date2unix("2022-01-01")
      );
    }

    @Watch("is_new5_type")
    onChangeIsNew5Type(newval, oldval) {
      if (!newval && oldval && this.tmp_new_loan.deduction_type >= 3) {
        this.tmp_new_loan.deduction_type = null;
      }
    }

    @Watch("is_new4_type")
    onChangeIsNew4Type(newval, oldval) {
      if (!newval && oldval && this.tmp_new_loan.is_specific >= 3) {
        this.tmp_new_loan.is_specific = null;
      }
    }

    get imgindex() {
      if (
        this.tmp_new_loan.lived_on &&
        util.obj2year(util.date2obj(this.tmp_new_loan.lived_on)) >= 2019
      ) {
        return "after2020_";
      } else {
        return "past_";
      }
    }
  }
