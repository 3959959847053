var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"infotable_wrap"},[_c('span',{staticClass:"boxhead"},[_vm._v("家屋の増改築(借入金)")]),_c('div',{staticClass:"join_edit_button"},[_c('common-button',{attrs:{"img_url":"common/edit","type":"green","isvalid":_vm.nencho_master_employee.reflected_to === null,"disable_reason":['すでに年末調整が反映済みなので、変更できません。']},on:{"clicked":function($event){return _vm.m.setStep(_vm.loan_step_number['reform_loan_money']['step'])}}},[_vm._v("修正")])],1),_c('table',{staticClass:"underbox"},[_vm._m(0),_c('tr',[_c('td',[_vm._v(_vm._s(_vm._f("number_format")(_vm.tmp_reform_loan.balance))+"円")])])]),_c('table',[_vm._m(1),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.util.yes_no_3[_vm.tmp_reform_loan.is_joint_debt]))])])]),(_vm.tmp_reform_loan.is_joint_debt == 1)?_c('table',[_c('tr',[_c('th',[_vm._v("あなたの負担割合"),(_vm.imgindex == 'after2020_')?_c('common-infobutton',{attrs:{"index":_vm.imgindex+'loan_reform_ratio'}}):_vm._e()],1)]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.tmp_reform_loan.your_ratio)+"%")])])]):_vm._e(),_c('table',[_vm._m(2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.util.yes_no_3[_vm.tmp_reform_loan.is_refinance]))])])]),(_vm.tmp_reform_loan.is_refinance == 1)?_c('table',[_vm._m(3),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm._f("number_format")(_vm.tmp_reform_loan.balance_before_refinance))+"円")])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(_vm._s(_vm._f("number_format")(_vm.tmp_reform_loan.balance_after_refinance))+"円")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("借入金等の年末残高(複数ある場合は合算)")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("住宅借入金等は連帯債務ですか")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("住宅借入金等の借換えをしていますか")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("借換えにおける借入金等の金額")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('span',[_vm._v("借換え直前の")]),_c('span',{staticClass:"font-red"},[_vm._v("当初")]),_c('span',[_vm._v("住宅借入金等残高")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',[_c('span',[_vm._v("借換えによる")]),_c('span',{staticClass:"font-red"},[_vm._v("新たな")]),_c('span',[_vm._v("住宅借入金等の当初金額")])])
}]

export { render, staticRenderFns }