
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import global from "@/vuex/company/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/company/manager";
  import header from "@/vuex/header";
  import Earning from "@/entities/Earning";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonInfobutton,
      CommonSelect,
    },
  })
  export default class Main extends Mixins(mixinNencho) {
    get imgindex() {
      return "after2020_";
    }

    change_reform_loan_num(val) {
      console.log(val);
      if (val == 0) {
        this.m.deleteReformLoan({ index: 0 });
        this.m.setReformLoanNum({ num: 0 });
      } else {
        this.m.setReformLoan({
          index: 0,
          reform_loan: {
            id: null,
            nencho_id: null,
            employee_id: null,
            lived_on: null,
            is_specific: 0,
            reform_money: null,
            reform_residential_money: null,
            balance: null,
            is_joint_debt: null,
            your_ratio: null,
            is_refinance: null,
            balance_before_refinance: null,
            balance_after_refinance: null,
            residential_ratio: null,
            is_specific_get: 0,
          },
        });
        this.m.setReformLoanNum({ num: 1 });
      }
    }
  }
