
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import CommonInfobutton from "@/components/common/Infobutton.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    CommonInfobutton,
    CommonButton,
  }
})
export default class Main extends Mixins(mixinNencho) {
  get tmp_reform_loan() {
    return this.m.reform_loans[0];
  }

  get is_reform_type() {
    return this.tmp_reform_loan.lived_on && util.date2unix(this.tmp_reform_loan.lived_on) >= util.date2unix('2014-04-01');
  }

  get imgindex() {
    if (this.tmp_reform_loan.lived_on && util.obj2year(util.date2obj(this.tmp_reform_loan.lived_on )) >= 2019) {
      return 'after2020_';
    } else {
      return 'past_';
    }
  }
}
