
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import { validationNewLoanCertificate, validationNewLoanMoney, validationReformLoanCertificate, validationReformLoanMoney } from '@/validations/nencho_loan';
import validate from '@/validations/validate';
import validate_api from "@/validations/validate_api";
import CommonProgress from "@/components/common/Progress.vue";
import { VStepper } from "vuetify/lib";
import { VStepperHeader } from "vuetify/lib";
import { VStepperStep } from "vuetify/lib";
import { VDivider } from "vuetify/lib";
import { VStepperItems } from "vuetify/lib";
import { VStepperContent } from "vuetify/lib";
import CommonBigbutton from "@/components/common/Bigbutton.vue";
import NenchoLoanNewloanNuminput from "@/components/nencho/loan/newloan/Numinput.vue";
import NenchoLoanNewloanCertificateInput from "@/components/nencho/loan/newloan/certificate/Input.vue";
import NenchoLoanNewloanMoneyInput from "@/components/nencho/loan/newloan/money/Input.vue";
import NenchoLoanReformloanNuminput from "@/components/nencho/loan/reformloan/Numinput.vue";
import NenchoLoanReformloanCertificateInput from "@/components/nencho/loan/reformloan/certificate/Input.vue";
import NenchoLoanReformloanMoneyInput from "@/components/nencho/loan/reformloan/money/Input.vue";
import NenchoLoanNewloanNumdisplay from "@/components/nencho/loan/newloan/Numdisplay.vue";
import NenchoLoanNewloanCertificateDisplay from "@/components/nencho/loan/newloan/certificate/Display.vue";
import NenchoLoanNewloanMoneyDisplay from "@/components/nencho/loan/newloan/money/Display.vue";
import NenchoLoanReformloanNumdisplay from "@/components/nencho/loan/reformloan/Numdisplay.vue";
import NenchoLoanReformloanCertificateDisplay from "@/components/nencho/loan/reformloan/certificate/Display.vue";
import NenchoLoanReformloanMoneyDisplay from "@/components/nencho/loan/reformloan/money/Display.vue";


@Component({
  components: {
    CommonProgress,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VDivider,
    VStepperItems,
    VStepperContent,
    CommonBigbutton,
    NenchoLoanNewloanNuminput,
    NenchoLoanNewloanCertificateInput,
    NenchoLoanNewloanMoneyInput,
    NenchoLoanReformloanNuminput,
    NenchoLoanReformloanCertificateInput,
    NenchoLoanReformloanMoneyInput,
    NenchoLoanNewloanNumdisplay,
    NenchoLoanNewloanCertificateDisplay,
    NenchoLoanNewloanMoneyDisplay,
    NenchoLoanReformloanNumdisplay,
    NenchoLoanReformloanCertificateDisplay,
    NenchoLoanReformloanMoneyDisplay,
  }
})
export default class Main extends Mixins(mixinNencho) {
  save = false;

  update_nencho_loan() {
    this.m.updateNenchoLoan({nencho_id: this.nencho_id, employee_id: this.employee_id, query: this.query});
  }

  get is_finish_loan_query() {
    return this.query.is_finish_loan;
  }

  @Watch('is_finish_loan_query')
  scroll_top(newval, oldval) {
    if (newval !== undefined && oldval === undefined) {
      this.scrollTop();
    }
  }

  get is_reform_new_type() {
    if (this.m.reform_loans[0] && this.m.reform_loans[0].lived_on !== null) {
      return this.m.reform_loans[0].lived_on && util.date2unix(this.m.reform_loans[0].lived_on) >= util.date2unix('2014-04-01');
    } else {
      return false;
    }
  }

  get step_number() {
    return this.loan_step_number;
  }

  get step() {
    return this.m.step;
  }
  set step(val) {
    this.m.setStep(val);
  }

  async next_step(step) {
    if (step < this.step) {
      this.step = step
    } else if (this.step_number['new_loan_certificate'] && this.step == this.step_number['new_loan_certificate']['step']) {
      var error = [];
      if (this.m.new_loan_num == 1) {
        error = error.concat(validationNewLoanCertificate(this.m.new_loans[0], this.nencho.year));
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['new_loan_money'] && this.step == this.step_number['new_loan_money']['step']) {
      var error = [];
      if (this.m.new_loan_num == 1) {
        error = error.concat(validationNewLoanMoney(this.m.new_loans[0]));
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['reform_loan_certificate'] && this.step == this.step_number['reform_loan_certificate']['step']) {
      var error = [];
      if (this.m.reform_loan_num == 1) {
        error = error.concat(validationReformLoanCertificate(this.m.reform_loans[0], this.nencho.year));
      }
      if (validate(error)) {
        this.step = step;
      }
    } else if (this.step_number['reform_loan_money'] && this.step == this.step_number['reform_loan_money']['step']) {
      var error = [];
      if (this.m.reform_loan_num == 1) {
        error = error.concat(validationReformLoanMoney(this.m.reform_loans[0]));
      }
      if (validate(error)) {
        this.step = step;
      }
    } else {
      this.step = step;
    }
  }

  save_nencho() {
    this.m.setSave({nencho_id: this.nencho_id, employee_id: this.employee_id, kind: 'loan', content: {
      new_loans: this.m.new_loans,
      reform_loans: this.m.reform_loans,
      step: this.m.step,
    }});
  }

  async created() {
    this.save = false;
    if (this.is_finish_loan_query === undefined) {
      this.m.setStep(0.1);
      if (this.employee_yearly.nencho_master.is_finish_loan == 1) {
        this.save = true;
        this.m.setNewLoanNum({num: this.loan_yearly.new_loans.length});
        this.m.setReformLoanNum({num: this.loan_yearly.reform_loans.length});
        this.m.setNewLoans({new_loans: this.loan_yearly.new_loans});
        this.m.setReformLoans({reform_loans: this.loan_yearly.reform_loans});
        this.m.setStep(this.step_number['confirm']['step']);
      } else {
        this.save = await this.m.getSave({nencho_id: this.nencho_id, employee_id: this.employee_id, kind: 'loan'});
        if (this.save !== null) {
          this.m.setNewLoanNum({num: this.save.content.new_loans.length});
          this.m.setReformLoanNum({num: this.save.content.reform_loans.length});
          this.m.setNewLoans({new_loans: this.save.content.new_loans});
          this.m.setReformLoans({reform_loans: this.save.content.reform_loans});
          this.m.setStep(this.save.content.step);
        } else {
          this.m.setNewLoanNum({num: 0});
          this.m.setReformLoanNum({num: 0});
          this.m.setNewLoans({new_loans: []});
          this.m.setReformLoans({reform_loans: []});
        }
      }
    } else {
      this.save = true;
    }
  }
}
