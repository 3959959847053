
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import mixinNencho from '@/mixins/mixinNencho';
import NenchoMasterNonencho from "@/components/nencho/master/Nonencho.vue";
import CommonProgress from "@/components/common/Progress.vue";
import NenchoLoanMain from "@/components/nencho/loan/Main.vue";

@Component({
  components: {
    NenchoMasterNonencho,
    CommonProgress,
    NenchoLoanMain,
  }
})
export default class EmployeeEach extends Mixins(mixinNencho) {
}
