import * as util from "@/util";
import * as vali from "@/validations/validationUtil";

export function validationNewLoanCertificate(new_loan, year) {
  var error = [];

  error.push(new_loan.lived_on !== null || "居住開始年月日を正しく入力してください");
  var ten_year = year - 10;
  error.push(
    (util.date2unix(new_loan.lived_on) >= util.date2unix("2007-01-01") &&
      util.date2unix(new_loan.lived_on) <= util.date2unix("2008-12-31")) ||
      util.date2unix(new_loan.lived_on) > util.date2unix(ten_year + "-12-31") ||
      "入力された居住開始年月日では住宅ローン控除を受けることはできません",
  );
  if (util.date2unix(new_loan.lived_on) >= util.date2unix("2014-04-01")) {
    if (util.date2unix(new_loan.lived_on) >= util.date2unix("2021-01-01")) {
      error.push(
        new_loan.is_specific !== null ||
          "「特定」又は「特別特定」又は「特例特別特例」の記載の有無を選択してください",
      );
    } else if (util.date2unix(new_loan.lived_on) >= util.date2unix("2019-10-01")) {
      error.push(
        new_loan.is_specific !== null || "「特定」又は「特別特定」の記載の有無を選択してください",
      );
    } else {
      error.push(new_loan.is_specific !== null || "「特定」の記載の有無を選択してください");
    }
  }
  error.push(new_loan.house_money !== null || "家屋の取得対価の額を入力してください");
  error.push(
    new_loan.house_money === null ||
      vali.is_number(new_loan.house_money) ||
      "家屋の取得対価の額は半角数字で入力してください",
  );
  error.push(
    new_loan.house_money === null ||
      !vali.is_number(new_loan.house_money) ||
      vali.is_plus(new_loan.house_money) ||
      "家屋の取得対価の額は0より大きい値を入力してください",
  );

  error.push(
    new_loan.land_money === null ||
      vali.is_number(new_loan.land_money) ||
      "土地の取得対価の額は半角数字で入力してください",
  );
  error.push(
    new_loan.land_money === null ||
      !vali.is_number(new_loan.land_money) ||
      new_loan.land_money == 0 ||
      vali.is_zeroplus(new_loan.land_money) ||
      "土地の取得対価の額は0より大きい値を入力してください",
  );

  if (util.date2unix(new_loan.lived_on) >= util.date2unix("2019-01-01")) {
    error.push(new_loan.house_residential_ratio !== null || "家屋の居住用割合を入力してください");
    error.push(
      new_loan.house_residential_ratio === null ||
        vali.is_float(new_loan.house_residential_ratio) ||
        "家屋の居住用割合は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.house_residential_ratio === null ||
        !vali.is_float(new_loan.house_residential_ratio) ||
        (new_loan.house_residential_ratio > 0 && new_loan.house_residential_ratio <= 100) ||
        "家屋の居住用割合は0より大きく100以下の値を入力してください",
    );

    error.push(
      new_loan.land_money === null ||
        new_loan.land_residential_ratio !== null ||
        "土地の取得対価が空白でない(土地の購入がある)場合は、土地の居住用割合を入力してください",
    );
    error.push(
      new_loan.land_residential_ratio === null ||
        vali.is_float(new_loan.land_residential_ratio) ||
        "土地の居住用割合は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.land_residential_ratio === null ||
        !vali.is_float(new_loan.land_residential_ratio) ||
        (new_loan.land_residential_ratio >= 0 && new_loan.land_residential_ratio <= 100) ||
        "土地の居住用割合は0より大きく100以下の値を入力してください",
    );
  } else {
    error.push(
      new_loan.land_money === null ||
        new_loan.house_area !== null ||
        "家屋の総床面積又は総面積を入力してください",
    );
    error.push(
      new_loan.house_area === null ||
        vali.is_float(new_loan.house_area) ||
        "家屋の総床面積又は総面積は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.house_area === null ||
        !vali.is_float(new_loan.house_area) ||
        vali.is_plus(new_loan.house_area) ||
        "家屋の総床面積又は総面積は0より大きい値を入力してください",
    );

    error.push(
      new_loan.land_money === null ||
        new_loan.land_area !== null ||
        "土地の取得対価が空白でない(土地の購入がある)場合は、土地の総床面積又は総面積を入力してください",
    );
    error.push(
      new_loan.land_area === null ||
        vali.is_float(new_loan.land_area) ||
        "土地の総床面積又は総面積は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.land_area === null ||
        !vali.is_float(new_loan.land_area) ||
        vali.is_plus(new_loan.land_area) ||
        "土地の総床面積又は総面積は0より大きい値を入力してください",
    );

    error.push(
      new_loan.house_residential_area !== null ||
        "家屋の居住用部分の床面積又は面積を入力してください",
    );
    error.push(
      new_loan.house_residential_area === null ||
        vali.is_float(new_loan.house_residential_area) ||
        "家屋の居住用部分の床面積又は面積は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.house_residential_area === null ||
        !vali.is_float(new_loan.house_residential_area) ||
        vali.is_plus(new_loan.house_residential_area) ||
        "家屋の居住用部分の床面積又は面積は0より大きい値を入力してください",
    );

    error.push(
      new_loan.land_money === null ||
        new_loan.land_area !== null ||
        "土地の取得対価が空白でない(土地の購入がある)場合は、土地の居住用部分の床面積又は面積入力してください",
    );
    error.push(
      new_loan.land_residential_area === null ||
        vali.is_float(new_loan.land_residential_area) ||
        "土地の居住用部分の床面積又は面積は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      new_loan.land_residential_area === null ||
        !vali.is_float(new_loan.land_residential_area) ||
        vali.is_plus(new_loan.land_residential_area) ||
        "土地の居住用部分の床面積又は面積は0より大きい値を入力してください",
    );
    if (new_loan.house_area !== null && new_loan.house_residential_area !== null) {
      error.push(
        Number(new_loan.house_area) >= Number(new_loan.house_residential_area) ||
          "家屋の居住用部分の床面積又は面積は家屋の総床面積又は総面積以下の値を入力してください",
      );
    }

    if (new_loan.land_area !== null && new_loan.land_residential_area !== null) {
      error.push(
        Number(new_loan.land_area) >= Number(new_loan.land_residential_area) ||
          "土地の居住用部分の床面積又は面積は土地の総床面積又は総面積以下の値を入力してください",
      );
    }
  }
  error.push(new_loan.deduction_type !== null || "住宅ローン控除の区分を選択してください");

  if (
    new_loan.is_specific == 1 &&
    new_loan.lived_on &&
    util.date2unix(new_loan.lived_on) >= util.date2unix("2014-04-01")
  ) {
    error.push(
      new_loan.deduction_type === null ||
        new_loan.deduction_type != 1 ||
        "居住開始年月日に「特定」の記載がある場合、住宅ローン控除の区分に「震災再取得等」を選択することはできません",
    );
  }

  if (util.date2unix(new_loan.lived_on) >= util.date2unix("2022-01-01")) {
    error.push(
      new_loan.is_special_residential !== null ||
        "区分に「特例居住用家屋」の記載があるかを選択してください",
    );
  }

  return error;
}

export function validationNewLoanMoney(new_loan) {
  var error = [];

  error.push(
    new_loan.house_detail == 1 ||
      new_loan.land_detail == 1 ||
      new_loan.house_land_detail == 1 ||
      "住宅借入金等の内訳を選択してください",
  );
  if (new_loan.house_detail == 1 || new_loan.land_detail == 1 || new_loan.house_land_detail == 1) {
    if (new_loan.house_detail == 1) {
      error.push(
        new_loan.house_balance !== null || "借入金等の年末残高(住宅のみ)を入力してください",
      );
      error.push(
        new_loan.house_balance === null ||
          vali.is_number(new_loan.house_balance) ||
          "借入金等の年末残高(住宅のみ)は半角数字で入力してください",
      );
      error.push(
        new_loan.house_balance === null ||
          !vali.is_number(new_loan.house_balance) ||
          vali.is_plus(new_loan.house_balance) ||
          "借入金等の年末残高(住宅のみ)は0より大きい値を入力してください",
      );
    }
    if (new_loan.land_detail == 1) {
      error.push(
        new_loan.land_balance !== null || "借入金等の年末残高(土地のみ)を入力してください",
      );
      error.push(
        new_loan.land_balance === null ||
          vali.is_number(new_loan.land_balance) ||
          "借入金等の年末残高(土地のみ)は半角数字で入力してください",
      );
      error.push(
        new_loan.land_balance === null ||
          !vali.is_number(new_loan.land_balance) ||
          vali.is_plus(new_loan.land_balance) ||
          "借入金等の年末残高(土地のみ)は0より大きい値を入力してください",
      );
    }
    if (new_loan.house_land_detail == 1) {
      error.push(
        new_loan.house_land_balance !== null ||
          "借入金等の年末残高(住宅及び土地等)を入力してください",
      );
      error.push(
        new_loan.house_land_balance === null ||
          vali.is_number(new_loan.house_land_balance) ||
          "借入金等の年末残高(住宅及び土地等)は半角数字で入力してください",
      );
      error.push(
        new_loan.house_land_balance === null ||
          !vali.is_number(new_loan.house_land_balance) ||
          vali.is_plus(new_loan.house_land_balance) ||
          "借入金等の年末残高(住宅及び土地等)は0より大きい値を入力してください",
      );
    }
  }

  if (util.date2unix(new_loan.lived_on) >= util.date2unix("2019-01-01")) {
    if (new_loan.house_detail == 1 || new_loan.house_land_detail == 1) {
      error.push(
        new_loan.is_house_joint_debt !== null ||
          "家屋の住宅借入金等が連帯債務かどうかを選択してください",
      );
      if (new_loan.is_house_joint_debt == 1) {
        error.push(
          new_loan.your_house_ratio !== null || "家屋部分のあなたの負担割合を入力してください",
        );
        error.push(
          new_loan.your_house_ratio === null ||
            vali.is_float(new_loan.your_house_ratio) ||
            "家屋部分のあなたの負担割合は半角数字(小数点を含む)で入力してください",
        );
        error.push(
          new_loan.your_house_ratio === null ||
            !vali.is_float(new_loan.your_house_ratio) ||
            (new_loan.your_house_ratio > 0 && new_loan.your_house_ratio <= 100) ||
            "家屋部分のあなたの負担割合は0より大きく100以下の値を入力してください",
        );
      }
    }
    if (new_loan.land_detail == 1 || new_loan.house_land_detail == 1) {
      error.push(
        new_loan.is_land_joint_debt !== null ||
          "土地の住宅借入金等が連帯債務かどうかを選択してください",
      );
      if (new_loan.is_land_joint_debt == 1) {
        error.push(
          new_loan.your_land_ratio !== null || "土地部分のあなたの負担割合は入力してください",
        );
        error.push(
          new_loan.your_land_ratio === null ||
            vali.is_float(new_loan.your_land_ratio) ||
            "土地部分のあなたの負担割合は半角数字(小数点を含む)で入力してください",
        );
        error.push(
          new_loan.your_land_ratio === null ||
            !vali.is_float(new_loan.your_land_ratio) ||
            (new_loan.your_land_ratio > 0 && new_loan.your_land_ratio <= 100) ||
            "土地部分のあなたの負担割合は0より大きく100以下の値を入力してください",
        );
      }
    }

    if (new_loan.house_land_detail == 1) {
      error.push(
        new_loan.is_house_joint_debt === null ||
          new_loan.is_land_joint_debt === null ||
          new_loan.is_house_joint_debt == new_loan.is_land_joint_debt ||
          "住宅借入金等の内訳で「住宅及び土地等」を選択している場合は、連帯債務の有無を家屋と土地で同じものを選択してください",
      );
      error.push(
        new_loan.your_house_ratio === null ||
          new_loan.your_land_ratio === null ||
          new_loan.your_house_ratio == new_loan.your_land_ratio ||
          "住宅借入金等の内訳で「住宅及び土地等」を選択している場合は、あなたの負担割合を家屋と土地で同じ値を入力してください",
      );
    }
  } else {
    error.push(
      new_loan.is_joint_debt !== null || "住宅借入金等が連帯債務かどうかを選択してください",
    );
    if (new_loan.is_joint_debt == 1) {
      error.push(new_loan.your_ratio !== null || "あなたの負担割合を入力してください");
      error.push(
        new_loan.your_ratio === null ||
          vali.is_float(new_loan.your_ratio) ||
          "あなたの負担割合は半角数字(小数点を含む)で入力してください",
      );
      error.push(
        new_loan.your_ratio === null ||
          !vali.is_float(new_loan.your_ratio) ||
          (new_loan.your_ratio > 0 && new_loan.your_ratio <= 100) ||
          "あなたの負担割合は0より大きく100以下の値を入力してください",
      );
    }
  }

  error.push(
    new_loan.is_refinance !== null || "住宅借入金等の借換えをしているかどうかを選択してください",
  );
  if (new_loan.is_refinance == 1) {
    error.push(
      new_loan.balance_before_refinance !== null ||
        "借換え直前の当初住宅借入金等残高を入力してください",
    );
    error.push(
      new_loan.balance_before_refinance === null ||
        vali.is_number(new_loan.balance_before_refinance) ||
        "借換え直前の当初住宅借入金等残高は半角数字で入力してください",
    );
    error.push(
      new_loan.balance_before_refinance === null ||
        !vali.is_number(new_loan.balance_before_refinance) ||
        vali.is_plus(new_loan.balance_before_refinance) ||
        "借換え直前の当初住宅借入金等残高は0より大きい値を入力してください",
    );
    error.push(
      new_loan.balance_after_refinance !== null ||
        "借換えによる新たな住宅借入金等の当初金額を入力してください",
    );
    error.push(
      new_loan.balance_after_refinance === null ||
        vali.is_number(new_loan.balance_after_refinance) ||
        "借換えによる新たな住宅借入金等の当初金額は半角数字で入力してください",
    );
    error.push(
      new_loan.balance_after_refinance === null ||
        !vali.is_number(new_loan.balance_after_refinance) ||
        vali.is_plus(new_loan.balance_after_refinance) ||
        "借換えによる新たな住宅借入金等の当初金額は0より大きい値を入力してください",
    );
  }

  return error;
}

export function validationReformLoanCertificate(reform_loan, year) {
  var error = [];

  error.push(reform_loan.lived_on !== null || "居住開始年月日を正しく入力してください");
  var ten_year = year - 10;
  error.push(
    (util.date2unix(reform_loan.lived_on) >= util.date2unix("2007-01-01") &&
      util.date2unix(reform_loan.lived_on) <= util.date2unix("2008-12-31")) ||
      util.date2unix(reform_loan.lived_on) > util.date2unix(ten_year + "-12-31") ||
      "入力された居住開始年月日では住宅ローン控除を受けることはできません",
  );
  if (util.date2unix(reform_loan.lived_on) >= util.date2unix("2014-04-01")) {
    if (util.date2unix(reform_loan.lived_on) >= util.date2unix("2019-10-01")) {
      error.push(
        reform_loan.is_specific_get !== null ||
          "住宅の取得は「特定」又は「特別特定」取得かどうかを選択してください",
      );
    } else {
      error.push(
        reform_loan.is_specific_get !== null ||
          "住宅の取得は「特定」取得かどうかを選択してください",
      );
    }
  }
  error.push(reform_loan.reform_money !== null || "増改築等の費用の額を入力してください");
  error.push(
    reform_loan.reform_money === null ||
      vali.is_number(reform_loan.reform_money) ||
      "増改築等の費用の額は半角数字で入力してください",
  );
  error.push(
    reform_loan.reform_money === null ||
      !vali.is_number(reform_loan.reform_money) ||
      vali.is_plus(reform_loan.reform_money) ||
      "増改築等の費用の額は0より大きい値を入力してください",
  );
  if (util.date2unix(reform_loan.lived_on) >= util.date2unix("2019-01-01")) {
    error.push(reform_loan.residential_ratio !== null || "居住用割合を入力してください");
    error.push(
      reform_loan.residential_ratio === null ||
        vali.is_float(reform_loan.residential_ratio) ||
        "居住用割合は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      reform_loan.residential_ratio === null ||
        !vali.is_float(reform_loan.residential_ratio) ||
        (reform_loan.residential_ratio > 0 && reform_loan.residential_ratio <= 100) ||
        "居住用割合は0より大きく100以下の値を入力してください",
    );
  } else {
    error.push(
      reform_loan.reform_residential_money !== null || "居住用部分の費用の額を入力してください",
    );
    error.push(
      reform_loan.reform_residential_money === null ||
        vali.is_number(reform_loan.reform_residential_money) ||
        "居住用部分の費用の額は半角数字で入力してください",
    );
    error.push(
      reform_loan.reform_residential_money === null ||
        !vali.is_number(reform_loan.reform_residential_money) ||
        vali.is_plus(reform_loan.reform_residential_money) ||
        "居住用部分の費用の額は0より大きい値を入力してください",
    );
  }
  if (util.date2unix(reform_loan.lived_on) >= util.date2unix("2019-10-01")) {
  }
  error.push(
    reform_loan.is_specific !== null || "特定増改築等の費用の額に記載の有無を選択してください",
  );

  return error;
}

export function validationReformLoanMoney(reform_loan) {
  var error = [];

  error.push(reform_loan.balance !== null || "借入金等の年末残高を入力してください");
  error.push(
    reform_loan.balance === null ||
      vali.is_number(reform_loan.balance) ||
      "借入金等の年末残高は半角数字で入力してください",
  );
  error.push(
    reform_loan.balance === null ||
      !vali.is_number(reform_loan.balance) ||
      vali.is_plus(reform_loan.balance) ||
      "借入金等の年末残高は0より大きい値を入力してください",
  );

  error.push(
    reform_loan.is_joint_debt !== null || "住宅借入金等が連帯債務かどうかを選択してください",
  );
  if (reform_loan.is_joint_debt == 1) {
    error.push(reform_loan.your_ratio !== null || "あなたの負担割合を入力してください");
    error.push(
      reform_loan.your_ratio === null ||
        vali.is_float(reform_loan.your_ratio) ||
        "あなたの負担割合は半角数字(小数点を含む)で入力してください",
    );
    error.push(
      reform_loan.your_ratio === null ||
        !vali.is_float(reform_loan.your_ratio) ||
        (reform_loan.your_ratio > 0 && reform_loan.your_ratio <= 100) ||
        "あなたの負担割合は0より大きく100以下の値を入力してください",
    );
  }

  error.push(
    reform_loan.is_refinance !== null || "住宅借入金等の借換えをしているかどうかを選択してください",
  );
  if (reform_loan.is_refinance == 1) {
    error.push(
      reform_loan.balance_before_refinance !== null ||
        "借換え直前の当初住宅借入金等残高を入力してください",
    );
    error.push(
      reform_loan.balance_before_refinance === null ||
        vali.is_number(reform_loan.balance_before_refinance) ||
        "借換え直前の当初住宅借入金等残高は半角数字で入力してください",
    );
    error.push(
      reform_loan.balance_before_refinance === null ||
        !vali.is_number(reform_loan.balance_before_refinance) ||
        vali.is_plus(reform_loan.balance_before_refinance) ||
        "借換え直前の当初住宅借入金等残高は0より大きい値を入力してください",
    );
    error.push(
      reform_loan.balance_after_refinance !== null ||
        "借換えによる新たな住宅借入金等の当初金額を入力してください",
    );
    error.push(
      reform_loan.balance_after_refinance === null ||
        vali.is_number(reform_loan.balance_after_refinance) ||
        "借換えによる新たな住宅借入金等の当初金額は半角数字で入力してください",
    );
    error.push(
      reform_loan.balance_after_refinance === null ||
        !vali.is_number(reform_loan.balance_after_refinance) ||
        vali.is_plus(reform_loan.balance_after_refinance) ||
        "借換えによる新たな住宅借入金等の当初金額は0より大きい値を入力してください",
    );
  }

  return error;
}
