
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import global from "@/vuex/company/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/company/manager";
  import header from "@/vuex/header";
  import Earning from "@/entities/Earning";
  import CommonInfobutton from "@/components/common/Infobutton.vue";
  import CommonSelect from "@/components/common/Select.vue";

  @Component({
    components: {
      CommonInfobutton,
      CommonSelect,
    },
  })
  export default class Main extends Mixins(mixinNencho) {
    get imgindex() {
      return "after2020_";
    }

    change_new_loan_num(val) {
      console.log(val);
      if (val == 0) {
        this.m.deleteNewLoan({ index: 0 });
        this.m.setNewLoanNum({ num: 0 });
      } else {
        this.m.setNewLoan({
          index: 0,
          new_loan: {
            id: null,
            nencho_id: null,
            employee_id: null,
            lived_on: null,
            is_specific: null,
            house_money: null,
            land_money: null,
            house_area: null,
            land_area: null,
            house_residential_area: null,
            land_residential_area: null,
            is_special_residential: null,
            deduction_type: null,
            house_detail: 0,
            land_detail: 0,
            house_land_detail: 0,
            house_balance: null,
            land_balance: null,
            house_land_balance: null,
            is_joint_debt: null,
            your_ratio: null,
            is_refinance: null,
            balance_before_refinance: null,
            balance_after_refinance: null,
            house_residential_ratio: null,
            land_residential_ratio: null,
            is_house_joint_debt: null,
            is_land_joint_debt: null,
            your_house_ratio: null,
            your_land_ratio: null,
          },
        });
        this.m.setNewLoanNum({ num: 1 });
      }
    }
  }
